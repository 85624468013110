import { useEffect, ReactElement } from "react";
import { useAuthStore } from "../store";

import { useNavigate, useSearchParams } from "react-router-dom";
import { UserRole } from "../../Constants";
// import { UserRole } from "@shoutout-labs/market_buzz_crm_types";

const AuthStoreProvider = ({ children }: { children: ReactElement }) => {
  const [
    isLoggedIn,
    setToken,
    logoutUser,
    organizationId,
    role,
    initCompleted
  ] = useAuthStore((state) => [
    state.isLoggedIn,
    state.setToken,
    state.logoutUser,
    state.organizationId,
    state.role,
    state.initCompleted
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = searchParams.get("token");
    if (accessToken) {
      setToken(accessToken);
      sessionStorage.setItem("accessToken", accessToken);
      searchParams.delete("token");
      setSearchParams(searchParams);
    } else if (!!sessionStorage.getItem("accessToken")) {
      setToken(sessionStorage.getItem("accessToken") ?? "");
    } else {
      logoutUser();
    }
  }, [setToken, logoutUser, searchParams, setSearchParams]);

  useEffect(() => {
    if (initCompleted) {
      if (!isLoggedIn()) {
        navigate("/login");
      } else if (!organizationId && role === UserRole.SUPERADMIN) {
        navigate("/admin/switch");
      }
    }
  }, [isLoggedIn, navigate, organizationId, role, initCompleted]);

  return children;
};

export default AuthStoreProvider;
