import { useState, useEffect, useCallback, useMemo } from "react";
import { Table } from "reactstrap";
import Pagination from "../../Common/Pagination";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { getCustomers } from "../../../services";
import UiOffcanvas from "../../../pages/BaseUi/UiOffCanvas/UiOffCanvas";
import Loader from "../../Common/Loader";
import { Utility } from "../../../utils";
import "./CustomerTable.scss";

interface CustomersTableProps {
  searchResults: TCustomerModelJSON[];
  searchCount: number;
  isSearchResult: boolean;
}

const CustomersTable: React.FC<CustomersTableProps> = ({
  searchResults,
  searchCount,
  isSearchResult
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [isRight, setIsRight] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCustomers, setSelectedCustomer, customerList, setCustomerList] =
    useCustomersStore((state) => [
      state.totalCustomers,
      state.setSelectedCustomer,
      state.customerList,
      state.setCustomerList
    ]);

  const loadCustomerData = useCallback(async () => {
    try {
      const queryObj = {
        limit,
        skip
      };
      const response = await getCustomers(queryObj);
      setCustomerList(response.items);
      if (response.items != null) {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [limit, skip, setCustomerList]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadCustomerData();
    }
  }, [isLoggedIn, loadCustomerData]);

  const displayedCustomers = useMemo(() => {
    return isSearchResult ? searchResults : customerList;
  }, [isSearchResult, searchResults, customerList]);

  const totalCustomersCount = useMemo(() => {
    return isSearchResult
      ? searchResults
        ? searchResults?.length
        : 0
      : totalCustomers;
  }, [isSearchResult, searchResults, totalCustomers]);

  const toggleRightCanvas = useCallback(
    (e: any) => {
      setIsRight(!isRight);
      const cusomterId = e.currentTarget.getAttribute("data-id");
      const customer = customerList.find(
        (customer) => customer.id === cusomterId
      );
      setSelectedCustomer(customer as TCustomerModelJSON);
    },
    [setIsRight, isRight, customerList, setSelectedCustomer]
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="table-responsive table-card">
            <Table className="align-middle table-nowrap mb-0 customer-table">
              <thead className="table-light">
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Avg. Spend</th>
                  <th scope="col" style={{ width: "150px" }}>
                    Visits
                  </th>
                  {/* <th scope="col">Last Activity</th>
    <th scope="col">Marketing Permission</th> */}
                </tr>
              </thead>
              <tbody>
                {displayedCustomers && displayedCustomers.length > 0 ? (
                  displayedCustomers.map((customer) => (
                    <tr
                      key={customer.referenceId}
                      onClick={toggleRightCanvas}
                      data-id={customer.id}
                      className=""
                    >
                      {/* <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-grow-1">{`${customer.firstName} ${customer.lastName}`}</div>
                  </div>
                </td> */}
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-grow-1">
                            {customer.firstName}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-grow-1">{customer.lastName}</div>
                        </div>
                      </td>
                      <td>{customer.email}</td>
                      <td>{customer.phoneNumber}</td>
                      <td>
                        ${" "}
                        {Utility.getAvgSpend(
                          (customer?.totalTransactionsCount as number) || 1,
                          (customer?.totalTransactionsSum as number) || 0
                        )}
                      </td>
                      <td>{customer?.totalTransactionsCount}</td>
                      {/* <td>
                  {customer.optIn.marketing.allowed ? (
                    <span className="badge bg-success">Enabled</span>
                  ) : (
                    <span className="badge bg-danger">Disabled</span>
                  )}
                </td> */}
                    </tr>
                  ))
                ) : (
                  <tr className="mt-5">
                    <td className="text-center" colSpan={4}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                Showing{" "}
                <span className="fw-semibold">
                  {" "}
                  {searchCount || displayedCustomers.length}{" "}
                </span>{" "}
                of <span className="fw-semibold">{totalCustomersCount}</span>{" "}
                Results
              </div>
            </div>
            <Pagination
              dataCount={totalCustomersCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={limit}
              setSkip={setSkip}
              loadCustomerData={loadCustomerData}
            />
          </div>
          <UiOffcanvas
            isRight={isRight}
            toggleRightCanvas={toggleRightCanvas}
          />
        </>
      )}
    </>
  );
};

export default CustomersTable;
