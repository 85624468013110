import { fetchGet, fetchPost, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";
import {
  TCampaignGetRequest,
  TCampaignGetResponse,
  TCampaignCreateRequest,
  TCampaignCreateResponse,
  TContentSuggestionRequest,
  TContentSuggestionResponse,
  TCampaignsCountResponse
} from "@shoutout-labs/market_buzz_crm_types";

const getCampaigns = (
  queryObj: TCampaignGetRequest
): Promise<TCampaignGetResponse> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}campaigns?${jsonToQueryParam(queryObj)}`
  );
};

const getCampaignsCount = (): Promise<TCampaignsCountResponse> => {
  return fetchGet(`${Constants.REACT_APP_API_BASE_URL}campaigns/count`);
};

const createCampaign = (
  payload: TCampaignCreateRequest
): Promise<TCampaignCreateResponse> => {
  return fetchPost(`${Constants.REACT_APP_API_BASE_URL}campaigns`, payload);
};

const regenerateContent = (
  payload: TContentSuggestionRequest
): Promise<TContentSuggestionResponse> => {
  return fetchPost(
    `${Constants.REACT_APP_API_BASE_URL}campaigns/content-suggesion`,
    payload
  );
};

export { getCampaigns, createCampaign, regenerateContent, getCampaignsCount };
