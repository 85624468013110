import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import UiCards from "../UiCards/UiCards";
import CampaignHistoryTable from "../../../Components/Modules/Campaigns/CampaignHistoryTable";

const UiTabs = () => {
  // Default Tabs
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Campaign Templates
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Campaign History
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1" id="templates">
                  <UiCards toggle={toggle} />
                </TabPane>
                <TabPane tabId="2" id="history">
                  <CampaignHistoryTable activeTab={activeTab} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UiTabs;
