import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const getCustomers = (queryObj: object): Promise<any> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}customers?${jsonToQueryParam(queryObj)}`
  );
};

const searchCustomers = (queryObj: object): Promise<any> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}customers/search?${jsonToQueryParam(queryObj)}`
  );
};

const getCustomersSearchCount = (queryObj: object): Promise<any> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}customers/search/count?${jsonToQueryParam(queryObj)}`
  );
};

const getCustomersCount = (): Promise<any> => {
  return fetchGet(`${Constants.REACT_APP_API_BASE_URL}customers/count`);
};

export {
  getCustomers,
  searchCustomers,
  getCustomersSearchCount,
  getCustomersCount
};
