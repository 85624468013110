import { useMemo } from "react";
import { Table } from "reactstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import AdminAuthService from "../../../services/AdminAuthService";
//import Pagination from "../../../Components/Common/Pagination";
import { getOragnizations } from "../../../services";
import "./OrganizationTable.scss";

interface OrganizationTableProps {
  searchResults: [];
  isSearchResult: boolean;
}

const OrganizationsTable: React.FC<OrganizationTableProps> = ({
  searchResults,
  isSearchResult
}) => {
  const {
    data: organizationsData,
    isLoading,
    error
  } = useQuery({
    queryKey: ["organizations"],
    queryFn: getOragnizations
  });

  const switchOrganization = useMutation({
    mutationFn: (selectedOrganizationId: string) => {
      console.log(selectedOrganizationId);
      return AdminAuthService.impersonate(selectedOrganizationId);
    },
    onSuccess(data) {
      window.open(data.url, "_blank");
    }
  });

  const handleSwitch = (event: any) => {
    event.preventDefault();
    const selectedOrganizationId = event.currentTarget.getAttribute("data-id");
    switchOrganization.mutate(selectedOrganizationId);
  };

  const displayedCustomers = useMemo(() => {
    return isSearchResult ? searchResults : organizationsData?.items || [];
  }, [isSearchResult, searchResults, organizationsData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="table-responsive table-card my-3">
        <Table className="align-middle table-nowrap mb-0 customer-table">
          <thead className="table-light">
            <tr>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            {displayedCustomers.length > 0 ? (
              displayedCustomers.map((org) => (
                <tr
                  key={org.id}
                  onClick={handleSwitch}
                  data-id={org.id}
                  className=""
                >
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-grow-1">{org.name}</div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="mt-5">
                <td className="text-center" colSpan={4}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* <Pagination
        dataCount={displayedCustomers.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={limit}
        setSkip={setSkip}
        loadCustomerData={loadOrganizationData}
      /> */}
    </>
  );
};

export default OrganizationsTable;
