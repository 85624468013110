import { useState, useCallback } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import {
  BuzzCreditPackages,
  PerCreditValue
} from "@shoutout-labs/marketbuzz-constants";
import { purchasePackage } from "../../../services";
import { useOrganizationStore } from "../../../Store/store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Package {
  id: string;
  dollarValue: number;
  creditValue: number;
}

interface SelectedPackage {
  package: Package | null;
}

const BillingPackages = () => {
  document.title = "Billing | MarketBuzz";
  const [selectedPackage, setSelectedPackage] = useState<SelectedPackage>({
    package: BuzzCreditPackages[0]
  });
  const [disableBuyPackageBtn, setDisableBuyPackageBtn] = useState(false);
  const [setCredits, setDollarValue] = useOrganizationStore((state) => [
    state.setCredits,
    state.setDollarValue
  ]);

  const handlePackageSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const id = event.currentTarget.getAttribute("data-id");
      const pkg = BuzzCreditPackages.find((pkg) => pkg.id === id);
      if (pkg) {
        setSelectedPackage({ package: pkg });
      }
    },
    [setSelectedPackage]
  );

  const handleBuyPackage = useCallback(async () => {
    try {
      if (selectedPackage.package) {
        const payload = {
          id: selectedPackage.package.id
        };
        setDisableBuyPackageBtn(true);
        const result = await purchasePackage(payload);
        const dollarValue = result?.credits * PerCreditValue;
        setCredits(result?.credits);
        setDollarValue(dollarValue);
        toast.success("Package purchased successfully.", {
          autoClose: 3000
        });
        setDisableBuyPackageBtn(false);
      }
    } catch (e) {
      console.error(e);
      toast.error("Failed to buy package. Please try again later.", {
        autoClose: 3000
      });
      setDisableBuyPackageBtn(false);
    }
  }, [selectedPackage, setCredits, setDollarValue, setDisableBuyPackageBtn]);

  const handleCancel = useCallback(() => {
    setSelectedPackage({ package: BuzzCreditPackages[0] });
  }, [setSelectedPackage]);

  return (
    <div className="mt-4">
      <Card className="border shadow-none">
        <CardBody>
          <h6 className="mb-4 mt-2">Buy Credit Packages</h6>
          <Row>
            <Col xl={8} l={8} md={8} sm={8}>
              <Row>
                {BuzzCreditPackages.map((pkg) => (
                  <Col key={pkg.id} md={3} className="mb-3">
                    <Button
                      color={
                        selectedPackage.package?.id === pkg.id
                          ? "primary"
                          : "outline-secondary"
                      }
                      onClick={handlePackageSelect}
                      // onMouseEnter={handlePackageSelect}
                      // onMouseLeave={handlePackageSelect}
                      data-id={pkg.id}
                      block
                    >
                      <div>
                        <strong>${pkg.dollarValue}</strong>
                      </div>
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xl={1} l={1} md={1} sm={1}></Col>
            <Col xl={3} l={3} md={3} sm={3}>
              {selectedPackage && (
                <div>
                  <h5>{selectedPackage.package?.creditValue} Buzz Credits</h5>
                  {
                    // Reffer per credit value to constants repo once it published
                  }
                  <p>${PerCreditValue} per credit</p>
                  <div className="d-flex flex-row mt-3">
                    <Button
                      color={disableBuyPackageBtn ? "secondary" : "primary"}
                      onClick={handleBuyPackage}
                      disabled={
                        !selectedPackage.package || disableBuyPackageBtn
                      }
                    >
                      Buy Package
                    </Button>
                    <Button
                      color="outline-danger"
                      onClick={handleCancel}
                      disabled={!selectedPackage.package}
                      className="mx-3"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default BillingPackages;
