import VerticalLayout from "./Layouts/index";
import RoutesComponent from "./Routes";
import AuthStoreProvider from "./Store/Providers/AuthStoreProvider";
import CustomerStoreProvider from "./Store/Providers/CustomerStoreProvider";
import OrganizationStoreProvider from "./Store/Providers/OrganizationProvider";
import { useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import "./App.css";
import "./assets/scss/themes.scss";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

const queryClient = new QueryClient();
function App() {
  const location = useLocation();
  const isBasicView =
    location.pathname === "/login" || location.pathname === "/admin/switch";
  return (
    <QueryClientProvider client={queryClient}>
      <AuthStoreProvider>
        {isBasicView ? (
          <RoutesComponent />
        ) : (
          <OrganizationStoreProvider>
            <CustomerStoreProvider>
              <VerticalLayout>
                <RoutesComponent />
              </VerticalLayout>
            </CustomerStoreProvider>
          </OrganizationStoreProvider>
        )}
      </AuthStoreProvider>
    </QueryClientProvider>
  );
}

export default App;
