import { useCallback, useState } from "react";
import {
  Col,
  Container,
  Row,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner
} from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import CustomersTable from "./CustomersTable";
import SearchOption from "../../Common/SearchOption";
import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import {
  searchCustomers,
  getCustomersSearchCount,
  getCustomersCount,
  getCustomers
} from "../../../services";
import { useOrganizationStore } from "../../../Store/store";
import { OrganizationStatus } from "../../constants/layout";

import "./CustomerPage.scss";

const CustomerPage = () => {
  document.title = "Customers List | MarketBuzz";
  const [searchResults, setSearchResults] = useState<TCustomerModelJSON[]>([]);
  const [searchCount, setSearchCount] = useState<number>(0);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skip, setSkip] = useState<number>(0);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const handleSearch = useCallback(
    async (value: string) => {
      try {
        const queryObj = {
          limit,
          skip,
          query: value
        };
        const queryObjSearch = {
          query: value
        };

        let searchCustomersResponse;
        let searchCountResponse;
        // const [searchCustomersResponse, searchCountResponse] =
        //   await Promise.all([
        //     searchCustomers(queryObj),
        //     getCustomersSearchCount(queryObjSearch)
        //   ]);

        if (value !== "") {
          [searchCustomersResponse, searchCountResponse] = await Promise.all([
            searchCustomers(queryObj),
            getCustomersSearchCount(queryObjSearch)
          ]);
        } else {
          [searchCustomersResponse, searchCountResponse] = await Promise.all([
            getCustomers(queryObj),
            getCustomersCount()
          ]);
        }

        setSearchResults(searchCustomersResponse.items);
        setSearchCount(searchCountResponse.count);
        setIsSearchResult(true);
      } catch (error) {
        console.error("Error searching customers:", error);
      }
    },
    [limit, skip, setSearchResults, setSearchCount, setIsSearchResult]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="toast-container position-absolute p-3 top-0 start-50 tarnslate-middle">
            <Toast
              isOpen={organization.status === OrganizationStatus.SYNCING}
              className="toast-border-primary mb-3"
            >
              <ToastHeader icon={<Spinner size="sm"></Spinner>}>
                Data Sync in Progress
              </ToastHeader>
              <ToastBody>Syncing customers data. Please wait!</ToastBody>
            </Toast>
          </div>
          <BreadCrumb title="Customers List" pageTitle="Customers" />
          <div className="search-div">
            <SearchOption handleSearch={handleSearch} />
          </div>
          <Row className="project-wrapper">
            <Col xxl={8}>Customers</Col>
            <CustomersTable
              searchResults={searchResults}
              searchCount={searchCount}
              isSearchResult={isSearchResult}
            />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CustomerPage;
