import { useState, useCallback } from "react";
import { searchOragnizations } from "../../../services";
import SearchOption from "../../../Components/Common/SearchOption";
import OrganizationsTable from "./OrganizationsTable";

const SwitchAccount = () => {
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [organizationSearchData, setOrganizationSearchData] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skip, setSkip] = useState<number>(0);

  const searchOrganizations = useCallback(
    async (query: string) => {
      const queryObj = {
        limit,
        skip,
        query: query
      };
      const res = await searchOragnizations(queryObj);
      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
    },
    [setIsSearchResult, setOrganizationSearchData]
  );

  // const switchOrganization = useMutation({
  //   mutationFn: (selectedOrganizationId: string) => {
  //     // Call your backend API to switch the organization using the selectedOrganizationId
  //     // You can update this function as per your backend API requirements
  //     console.log(selectedOrganizationId);
  //     return AdminAuthService.impersonate(selectedOrganizationId);
  //   },
  //   onSuccess(data) {
  //     window.open(data.url, "_blank");
  //   }
  // });

  // const handleSwitch = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const selectedOrganizationId = (event.target as any).organization.value;
  //   switchOrganization.mutate(selectedOrganizationId);
  // };

  return (
    <div
      className="page-content d-flex justify-content-center align-items-center"
      //style={{ height: "100vh" }}
    >
      <div>
        <h1>Switch account</h1>
        {/* {isLoading && <Loader isLoading={isLoading} />}
        {error && <div>Error: {error.message}</div>} */}
        <SearchOption handleSearch={searchOrganizations} />
        {/* <Form onSubmit={handleSwitch}>
          <FormGroup>
            <Label for="exampleSelect">Select Organization</Label>
            <Input id="organizationSelect" name="organization" type="select">
              {data &&
                data.items.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          <Button type="submit">
            {switchOrganization.isPending ? "Sending..." : "Switch"}
          </Button>
        </Form> */}
        <OrganizationsTable
          searchResults={organizationSearchData as []}
          isSearchResult={isSearchResult}
        />
      </div>
    </div>
  );
};
export default SwitchAccount;
