import { useEffect, ReactElement, useCallback } from "react";
import { useAuthStore, useOrganizationStore } from "../store";
import { getOragnizationData } from "../../services";
import { PerCreditValue } from "@shoutout-labs/marketbuzz-constants";

const OrganizationStoreProvider = ({
  children
}: {
  children: ReactElement;
}) => {
  const [isLoggedIn, organizationId] = useAuthStore((state) => [
    state.isLoggedIn,
    state.organizationId
  ]);
  const [{ setCredits, setDollarValue, setOrganization }] =
    useOrganizationStore((state) => [state]);

  const getOrganizationCredits = useCallback(async () => {
    try {
      const response = await getOragnizationData();
      setCredits(response?.credits as number);
      if (response?.credits && response?.credits > 0) {
        const dollarValue = response?.credits * PerCreditValue;
        setDollarValue(dollarValue);
      }
      setOrganization(response);
    } catch (e) {
      console.error(e);
    }
  }, [setCredits, setDollarValue, setOrganization]);

  useEffect(() => {
    if (isLoggedIn() && organizationId) {
      getOrganizationCredits();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isLoggedIn, organizationId]);

  return children;
};

export default OrganizationStoreProvider;
