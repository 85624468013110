import { create } from "zustand";
import {
  TCustomerModelJSON,
  TOrganizationModelJSON
  // UserRole
} from "@shoutout-labs/market_buzz_crm_types";
import { Utility } from "../utils";
import { UserRole } from "../Constants";

type SideBarStore = {
  sidebarVisibilityType: string;
  changeSidebarVisibility: (sidebarVisibilitytype: string) => void;
  layoutType: string;
  leftSidebarType: string;
  layoutModeType: string;
  layoutWidthType: string;
  layoutPositionType: string;
  topbarThemeType: string;
  leftsidbarSizeType: string;
  leftSidebarViewType: string;
};

type AuthStore = {
  user: object;
  setUser: (user: object) => void;
  token: string;
  role: string;
  organizationId: string;
  initCompleted: boolean;
  setToken: (token: string) => void;
  logoutUser: () => void;
  isLoggedIn: () => boolean;
};

type CustomersStore = {
  totalCustomers: number;
  setTotalCustomers: (totalCustomers: number) => void;
  selectedCustomer: TCustomerModelJSON;
  setSelectedCustomer: (selectedCustomer: TCustomerModelJSON) => void;
  customerList: TCustomerModelJSON[];
  setCustomerList: (customerList: TCustomerModelJSON[]) => void;
};

type OrganizationStore = {
  credits: number;
  setCredits: (credits: number) => void;
  dollarValue: number;
  setDollarValue: (dollarValue: number) => void;
  organization: TOrganizationModelJSON;
  setOrganization: (organization: TOrganizationModelJSON) => void;
};

export const useSideBarVisibilityStore = create<SideBarStore>((set) => ({
  sidebarVisibilityType: "show",
  changeSidebarVisibility: (sidebarVisibilitytype: string) => {
    Utility.changeHTMLAttribute(
      "data-sidebar-visibility",
      sidebarVisibilitytype
    );
  },
  layoutType: "vertical",
  leftSidebarType: "light",
  layoutModeType: "light",
  layoutWidthType: "lg",
  layoutPositionType: "fixed",
  topbarThemeType: "light",
  leftsidbarSizeType: "lg",
  leftSidebarViewType: "default"
}));

export const useAuthStore = create<AuthStore>((set, get) => ({
  user: {
    name: "Unknown"
  },
  setUser: (user: object) => set(() => ({ user })),
  token: "",
  role: UserRole.USER,
  organizationId: "",
  initCompleted: false,
  setToken: (token: string) => {
    if (token) {
      const decodedToken = Utility.decodeToken(token);

      set(() => ({
        token,
        role: decodedToken.user.role,
        organizationId: decodedToken.user.organizationId,
        initCompleted: true
      }));
    } else {
      set({ token: "", initCompleted: true });
    }
  },
  logoutUser: () => {
    sessionStorage.removeItem("accessToken");
    set({ token: "", initCompleted: true });
    // window.location.href = `${Constants.REACT_APP_API_BASE_URL}auth/clover/login`;
  },
  isLoggedIn: () => !!get().token
}));

export const useCustomersStore = create<CustomersStore>((set) => ({
  totalCustomers: 0,
  setTotalCustomers: (totalCustomers: number) =>
    set(() => ({
      totalCustomers
    })),
  selectedCustomer: {} as TCustomerModelJSON,
  setSelectedCustomer: (selectedCustomer: TCustomerModelJSON) =>
    set(() => ({
      selectedCustomer
    })),
  customerList: [] as TCustomerModelJSON[],
  setCustomerList: (customerList: TCustomerModelJSON[]) =>
    set(() => ({
      customerList
    }))
}));

export const useOrganizationStore = create<OrganizationStore>((set) => ({
  credits: 0,
  setCredits: (credits: number) => set({ credits }),
  dollarValue: 0,
  setDollarValue: (dollarValue: number) => set({ dollarValue }),
  organization: {} as TOrganizationModelJSON,
  setOrganization: (organization: TOrganizationModelJSON) =>
    set(() => ({
      organization
    }))
}));
