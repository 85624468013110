import { Container, Row } from "reactstrap";
//import BreadCrumb from "../../Common/BreadCrumb";
import UiTabs from "../../../pages/BaseUi/UiTabs/UiTabs";

const CampaignPage = () => {
  document.title = "Campaigns | MarketBuzz";
  return (
    <div className="page-content">
      <Container fluid>
        {/* <BreadCrumb title="SMS Campaigns" pageTitle="SMS Campaigns" /> */}
        <Row className="project-wrapper">
          <UiTabs />
        </Row>
      </Container>
    </div>
  );
};

export default CampaignPage;
