import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { DateBucketEnum, DateRange } from "./Components/constants/dateRange";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

export class Utility {
  /**
   * Changes the body attribute
   */
  public static changeHTMLAttribute = (
    attribute: string,
    value: string
  ): boolean => {
    if (document.documentElement) {
      document.documentElement.setAttribute(attribute, value);
    }
    return true;
  };

  /**
   * Checks if the message contains custom attributes enclosed in square brackets.
   */
  public static hasCustomAttributes = (message: string): boolean => {
    return /\[.*?\]/g.test(message);
  };

  /**
   * Finds custom attributes enclosed in square brackets in the message.
   */
  public static findCustomAttributes = (message: string): string[] => {
    const matches = message.match(/\[.*?\]/g);
    return matches ? matches.map((match) => match.slice(1, -1)) : [];
  };

  /**
   * Get the average spend of a customer
   */

  public static getAvgSpend = (
    totalTransactionsCount: number,
    totalTransactionsSum: number
  ): number => {
    const avgSpend = totalTransactionsSum / totalTransactionsCount;
    return avgSpend;
  };

  public static getCustomerIndex = (
    selectedCustomerId: string,
    customerList: TCustomerModelJSON[]
  ) => {
    const selectedCusotmerIndex = customerList.findIndex(
      (customer) => customer.id === selectedCustomerId
    );
    return selectedCusotmerIndex;
  };

  /** Analytics Utils */

  // date util

  public static getDateRange = (range: string): DateRangeResult => {
    const today = new Date();
    const start = new Date();
    const compareStartDate = new Date();
    const endDate = new Date();
    const compareEndDate = new Date();

    switch (range) {
      case DateRange.TODAY:
        start.setDate(today.getDate());
        compareStartDate.setDate(today.getDate() - 1);
        endDate.setDate(today.getDate());
        compareEndDate.setDate(today.getDate() - 1);
        break;
      case DateRange.LAST_7_DAYS:
        start.setDate(today.getDate() - 7);
        compareStartDate.setDate(today.getDate() - 14);
        endDate.setDate(today.getDate() - 1);
        compareEndDate.setDate(today.getDate() - 8);
        break;
      case DateRange.LAST_28_DAYS:
        start.setDate(today.getDate() - 28);
        compareStartDate.setDate(today.getDate() - 56);
        endDate.setDate(today.getDate() - 1);
        compareEndDate.setDate(today.getDate() - 29);
        break;
      case DateRange.LAST_90_DAYS:
        start.setDate(today.getDate() - 90);
        compareStartDate.setDate(today.getDate() - 180);
        endDate.setDate(today.getDate() - 1);
        compareEndDate.setDate(today.getDate() - 91);
        break;
      case DateRange.LAST_365_DAYS:
        start.setDate(today.getDate() - 365);
        compareStartDate.setDate(today.getDate() - 730);
        endDate.setDate(today.getDate() - 1);
        compareEndDate.setDate(today.getDate() - 366);
        break;
      default:
        start.setDate(today.getDate() - 28);
        compareStartDate.setDate(today.getDate() - 56);
        endDate.setDate(today.getDate() - 1);
        compareEndDate.setDate(today.getDate() - 29);
        break;
    }

    return {
      startDate: start.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      compareStartDate: compareStartDate.toISOString().split("T")[0],
      compareEndDate: compareEndDate.toISOString().split("T")[0]
    };
  };

  public static extractNumericValueFromString = (text: string): number => {
    const match = text.match(/\d+/);
    let numericValue = 0;

    if (match) {
      numericValue = parseInt(match[0], 10);
    }
    return numericValue;
  };

  public static iterateDateRange = (
    startDate: Date,
    endDate: Date,
    callback: (dateKey: string) => void,
    interval: DateBucketEnum
  ) => {
    const start = moment(startDate);
    const end = moment(endDate);
    let current = start.clone();

    if (interval === DateBucketEnum.QUARTER) {
      const startQ = moment(startDate).startOf("quarter");
      const endQ = moment(endDate).endOf("quarter");
      let currentQ = startQ.clone();

      while (currentQ <= endQ) {
        callback(currentQ.format("YYYY-[Q]Q"));
        currentQ.add(1, "quarters");
      }
    } else {
      while (current <= end) {
        switch (interval) {
          case DateBucketEnum.DAY:
            callback(current.format("YYYY-MM-DD"));
            current.add(1, "days");
            break;
          case DateBucketEnum.WEEK:
            current.startOf("isoWeek");
            callback(current.format("YYYY-MM-DD"));
            current.add(1, "weeks");
            break;
          case DateBucketEnum.MONTH:
            callback(current.format("YYYY-MM"));
            current.add(1, "months");
            break;
          // case DateBucketEnum.QUARTER:
          //   callback(current.format("YYYY-[Q]Q"));
          //   current.add(1, "quarters");
          //   break;
          case DateBucketEnum.YEAR:
            callback(current.format("YYYY"));
            current.add(1, "years");
            break;
          default:
            throw new Error("Invalid interval");
        }
      }
    }
  };
  /**
   * Sets the default date range to the past month.
   * For example, if today is July 4th, the period will be June 4th to July 4th.
   * This function helps in initializing date range selections to a meaningful period by default.
   *
   */
  public static defaultDateRange = () => {
    const endDate = moment().toDate();
    const startDate = moment().subtract(1, "month").toDate();
    return { startDate, endDate, key: "selection" };
  };

  public static decodeToken(token: string): {
    user: { role: string; organizationId: string; username: string };
  } {
    return jwtDecode(token);
  }
}

interface DateRangeResult {
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
}
