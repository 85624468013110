const Constants = {
  REACT_APP_API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://api.beta.marketbuzz.ai/api/crm_service/",
  REACT_APP_FRONTNEND_URL:
    process.env.REACT_APP_FRONTNEND_URL || "https://app.beta.marketbuzz.ai/"
};

export enum UserRole { //TODO: Need to refer from types. For some reason, it gives an error at the moment
  USER = "USER",
  SUPERADMIN = "SUPERADMIN"
}

export default Constants;
