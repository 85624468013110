import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import UiCampaignEditModal from "../UiModals/UiCampaignEditModal";
import { getTemplates } from "../../../services";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import { TTemplateModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { PerCreditValue } from "@shoutout-labs/marketbuzz-constants";
import { CampaignName } from "../../../Components/constants/campaign";
import Loader from "../../../Components/Common/Loader";

const UiCards: React.FC<{ toggle: any }> = ({ toggle }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skip, setSkip] = useState<number>(0);
  const [templates, setTemplates] = useState<TTemplateModelJSON[]>([]);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [totalCustomers] = useCustomersStore((state) => [state.totalCustomers]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadTemplateData = useCallback(async () => {
    try {
      setLoading(true);
      const queryObj = {
        limit,
        skip
      };
      const response = await getTemplates(queryObj);
      setTemplates(response.items);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [limit, skip, setTemplates]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadTemplateData();
    }
  }, [isLoggedIn, loadTemplateData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          {templates.length > 0 &&
            templates.map((template, index) => (
              <Col xl={14} key={index}>
                <Card>
                  <CardBody>
                    <div className="d-flex position-relative">
                      <div>
                        <h6 className="mt-0">{template.type}</h6>
                        <Row>
                          <Col xl={6} lg={6} md={6} sm={12}>
                            <p>Message Text</p>
                            <Row>
                              <p>{template.messageBody}</p>
                            </Row>
                          </Col>
                          <Col xl={2} lg={2} md={2} sm={4}>
                            <p>No. Customers</p>
                            <Row>
                              <p>{totalCustomers}</p>
                            </Row>
                          </Col>
                          <Col xl={2} lg={2} md={2} sm={4}>
                            <p>Estimated Cost</p>
                            <Row>
                              <p>
                                {template?.costPerMessage * totalCustomers} Buzz
                                Credit/s
                              </p>
                              <p>
                                $
                                {(
                                  template?.costPerMessage *
                                  totalCustomers *
                                  PerCreditValue
                                ).toFixed(3)}
                              </p>
                            </Row>
                          </Col>
                          <Col xl={2} lg={2} md={2} sm={4}>
                            <p></p>
                            <Row>
                              <p>
                                <UiCampaignEditModal
                                  fade={true}
                                  messageText={template.messageBody}
                                  name={
                                    template.type as unknown as CampaignName
                                  }
                                  toggleTab={toggle}
                                  costPerMessage={template?.costPerMessage}
                                />
                              </p>
                            </Row>
                          </Col>
                        </Row>
                        {templates.length === 0 && <p>No data found..</p>}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      )}
    </>
  );
};

export default UiCards;
