import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
import { useSideBarVisibilityStore } from "../Store/store";

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState("");
  const [
    sidebarVisibilitytype,
    layoutType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarType
  ] = useSideBarVisibilityStore((state) => [
    state.sidebarVisibilityType,
    state.layoutType,
    state.layoutModeType,
    state.layoutWidthType,
    state.layoutPositionType,
    state.topbarThemeType,
    state.leftsidbarSizeType,
    state.leftSidebarViewType,
    state.leftSidebarType
  ]);

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }
  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    sidebarVisibilitytype
  ]);

  useEffect(() => {
    if (sidebarVisibilitytype === "show" || layoutType === "vertical") {
      document.querySelector(".hamburger-icon")?.classList.remove("open");
    } else {
      document.querySelector(".hamburger-icon")?.classList.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header headerClass={headerClass} layoutModeType={layoutModeType} />
        <Sidebar />
        <div className="main-content">
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object
};

export default Layout;
